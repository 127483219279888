import { CloseButton } from '@icons/CloseButton';
import { PlayButton } from '@icons/PlayButton';
import { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Text as Paragraph, Title } from '../Typography';
import { Close, CloseBtn, GlobalStyle, ModelContainer, TitleWrapper } from './styles';

export interface IModalBoxProps {
  colorScheme?: string;
  videoID?: string;
  playButton?: ReactNode;
  title?: string;
  description: string;
  thumbnailUrls: string[];
  embedUrl: string;
  uploadDate: string;
  duration: string;
  interactionCount: number;
  video?: string;
  keywords?: string;
  recipeCuisine?: string;
  recipeCategory?: string;
}

const modelContainerStyle = 'bg-white h-4/5 rounded-2xl overflow-hidden modal-main';

export const ModalBox = ({
  videoID = '',
  colorScheme = 'Grey',
  playButton = '',
  title,
  description,
  thumbnailUrls,
  embedUrl,
  uploadDate,
  duration,
  interactionCount,
  video,
  keywords,
  recipeCategory,
  recipeCuisine,
}: IModalBoxProps) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [Player, setPlayer] = useState<any>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  function openModal() {
    setIsOpen(true);
  }

  const mounted = () => import('react-youtube').then((module) => module.default);

  const onPlayerReady = (event: any) => {
    event.target.mute();
    event.target.playVideo();
  };

  useEffect(() => {
    if (modalIsOpen && !isMounted) {
      mounted().then((Module) => {
        setPlayer(
          <Module
            videoId={videoID}
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                autoplay: 1,
                playsinline: 1,
              },
            }}
            onReady={onPlayerReady}
          />,
        );
        setIsMounted(true);
      });
    }
  }, [modalIsOpen]);

  const closeModal = () => setIsOpen(false);

  const closeButton = (
    <CloseBtn className="mx-auto inline-block">
      <CloseButton />
    </CloseBtn>
  );

  const modalClassNames = `outline-none sm:w-11/12 md:w-10/12 md:h-modalVideoDesktop sm:h-modalVideoMobile
  transform md:translate-x-12 sm:translate-x-7 md:translate-y-4 xl:translate-y-8 3xl:translate-y-10 sm:-translate-y-20 ${
    title ? 'sm:mt-5' : 'sm:mt-10'
  } md:mt-0`;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: title,
    description: description,
    video: video,
    keywords: keywords,
    recipeCategory: recipeCategory,
    recipeCuisine: recipeCuisine,
    contentUrl: `https://www.youtube.com/watch?v=${videoID}`,
    ...(thumbnailUrls && { thumbnailUrl: thumbnailUrls }),
    ...(embedUrl && { embedUrl: `https://www.youtube.com/embed/${videoID}` }),
    ...(uploadDate && { uploadDate: uploadDate }),
    ...(duration && { duration: duration }),
    ...(interactionCount && {
      interactionStatistic: {
        '@type': 'InteractionCounter',
        interactionType: { '@type': 'WatchAction' },
        userInteractionCount: interactionCount,
      },
    }),
  };

  return (
    <>
      <GlobalStyle />
      <button onClick={openModal} type="button">
        {playButton || <PlayButton colorScheme={colorScheme} />}
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        ariaHideApp={false}
        className={modalClassNames}
      >
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        <TitleWrapper className="flex justify-between items-start sm:flex-col md:flex-row">
          <Close
            onClick={closeModal}
            className="cursor-default modal-close sm:block md:hidden w-full text-right pb-5"
          >
            {closeButton}
          </Close>
          {title && (
            <Title className="inline-block text-white" tag="p" type="sm">
              {title}
            </Title>
          )}
          <Close
            onClick={closeModal}
            className={`cursor-default modal-close sm:hidden md:inline-block ${
              !title && 'w-full text-right'
            }`}
          >
            {closeButton}
          </Close>
        </TitleWrapper>

        <ModelContainer className={modelContainerStyle}>{isMounted && Player}</ModelContainer>
        {description && (
          <Paragraph className="text-white pt-5 pb-1" tag="p" type="md">
            {description}
          </Paragraph>
        )}
      </Modal>
    </>
  );
};
