import { ParagraphText } from '@/components/ParagraphText';
import { AVAILABLE_THEMES_TYPE, AVAILABLE_THEMES } from '@/styles/constants';
import { SuperText } from '../styles';

export const SuperTextElement = ({
  text,
  id,
  themeName,
  textColor,
}: {
  text: string;
  id: string;
  themeName?: AVAILABLE_THEMES_TYPE;
  textColor?: 'Black' | 'White';
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <ParagraphText data-test={[`${id}-text`]} type="s">
        {text}
      </ParagraphText>
    );
  }

  return (
    <SuperText tag="p" type="lead" data-test={`${id}-super-text`} $color={textColor}>
      {text}
    </SuperText>
  );
};
