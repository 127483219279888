import { COLORS } from 'styles/constants';

export const CloseButton = ({
  width = '80',
  backgroundColor = COLORS.primary,
}: {
  width?: string;
  backgroundColor?: string;
}) => (
  <svg width={width} height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <ellipse cx="40" cy="40" rx="40" ry="40" transform="rotate(-90 40 40)" fill={backgroundColor} />
    <line x1="47.0704" y1="32.9293" x2="32.928" y2="47.0717" stroke="white" strokeWidth="2" />
    <line x1="47.0722" y1="47.0714" x2="32.9298" y2="32.929" stroke="white" strokeWidth="2" />
    <circle cx="40.0003" cy="39.9998" r="30.8519" stroke="white" strokeWidth="2" />
  </svg>
);
