import { COLORS } from 'styles/constants';

interface IPlayButtonProps {
  colorScheme?: string;
}

export const PlayButton = ({ colorScheme = 'Grey' }: IPlayButtonProps) => {
  let playColorScheme;
  switch (colorScheme) {
    case 'Black': {
      playColorScheme = {
        playButton: COLORS.white,
        innerCircleBackground: COLORS.black,
        outerCircleBackground: COLORS.white,
      };
      break;
    }
    case 'White': {
      playColorScheme = {
        playButton: COLORS.black,
        innerCircleBackground: COLORS.white,
        outerCircleBackground: COLORS.black,
      };
      break;
    }
    case 'Grey': {
      playColorScheme = {
        playButton: COLORS.white,
        innerCircleBackground: COLORS.primary,
        outerCircleBackground: COLORS.white,
      };
      break;
    }
    default: {
      playColorScheme = {
        playButton: COLORS.white,
        innerCircleBackground: COLORS.primary,
        outerCircleBackground: COLORS.white,
      };
    }
  }

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 109 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Play</title>
      <circle
        cx="54.5"
        cy="54"
        r="54"
        transform="rotate(-90 54.5 54)"
        fill={playColorScheme.innerCircleBackground}
      />
      <path
        d="M48.5 42L64.5 54.0003L48.5 66V42Z"
        fill={playColorScheme.playButton}
        stroke={playColorScheme.playButton}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="54.5"
        cy="54"
        r="42"
        stroke={playColorScheme.outerCircleBackground}
        strokeWidth="2"
      />
    </svg>
  );
};
