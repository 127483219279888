import { NextLink } from 'components/NextLink';
import FadeInSection from '../FadeInSection';
import { Grid, GridItem, GridRow } from '../Grid';
import { LiquidButton } from '../LiquidButton';
import { ModalBox } from '../ModalBox';
import { Text as Paragraph } from '../Typography';
import {
  BannerActions,
  BannerContent,
  BannerContentWrapper,
  BannerImage,
  BannerWrapper,
  CTAContainer,
  PlayButtonWrapper,
  PlayVideoWrapper,
  StyledButtonContainer,
} from './styles';
import type { IHeroBannerProps } from './types';

import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import NextImage from 'components/NextImage';
import { LinksService } from 'service/links/linksService';
import { AVAILABLE_THEMES, COLORS } from 'styles/constants';
import { MOBILE_MEDIA_BP } from '../../config/config';
import { SuperTextElement } from './components/SuperTextElement';
import { TitleElement } from './components/TitleElement';
import { DescElement } from './components/DescElement';

const LiquidButtonElement = ({
  text,
  width,
  color,
  textColor,
  variant,
}: {
  text: string;
  width: number;
  color: string;
  textColor: string;
  variant?: 'primary' | 'secondary' | 'none';
}) => (
  <LiquidButton
    variant={variant}
    text={text}
    height={50}
    width={width}
    color={color}
    textColor={textColor}
  />
);

export const HeroBanner = (
  {
    alignment = 'Left',
    verticalAlignment,
    verticalAlignmentMobile,
    title = '',
    description = '',
    textColor,
    buttonColor,
    link,
    image,
    mobileImage,
    video,
    watchVideoLabel = 'Video',
    playColorScheme = 'Grey',
    bannerSize = 'Tall',
    superText,
    titleTag,
    isRecipeBanner = false,
    trackingEvents,
    priority,
    thumbnailUrls,
    embedUrl,
    uploadDate,
    duration,
    interactionCount,
    themeName,
  }: IHeroBannerProps,
  { id = 'hero-banner' },
) => {
  const { pathTranslations } = useGlobalContext();
  let ColStart = 2;
  let ColSpan = 8;
  if (bannerSize === 'Short') {
    ColStart = 1;
    ColSpan = 7;
  }
  if (alignment === 'Center') {
    ColStart = 4;
    ColSpan = 10;
  } else if (alignment === 'Right') {
    ColStart = 10;
    ColSpan = 8;
  }
  const LinkUrl = link ? LinksService.getInternalLinks({ item: link, pathTranslations }) : null;
  const handleCTAClick = () => {
    if (trackingEvents?.clickHeroBannerCTA) {
      trackingEvents?.clickHeroBannerCTA();
    }
  };

  const sourcesRecipes = [
    {
      srcSet: image?.desktopXXL,
      type: 'image/webp',
      media: '(min-width: 1920px)',
      width: image?.width,
      height: image?.height,
    },
    {
      srcSet: image?.desktopXL,
      type: 'image/webp',
      media: '(min-width: 1680px)',
      width: image?.width,
      height: image?.height,
    },
    {
      srcSet: image?.desktopL,
      type: 'image/webp',
      media: '(min-width: 1440px)',
      width: image?.width,
      height: image?.height,
    },
    {
      srcSet: image?.desktop,
      type: 'image/webp',
      media: '(min-width: 768px)',
      width: image?.width,
      height: image?.height,
    },
    {
      srcSet: image?.mobile,
      type: 'image/webp',
      media: MOBILE_MEDIA_BP,
      width: image?.width,
      height: image?.height,
    },
  ];

  const sources = [
    {
      srcSet: image?.desktop,
      type: 'image/webp',
      media: '(min-width: 768px)',
      width: image?.width,
      height: image?.height,
    },
    {
      srcSet: mobileImage && image && !isRecipeBanner ? mobileImage.mobile : image?.mobile,
      type: 'image/webp',
      media: MOBILE_MEDIA_BP,
      width: image?.width,
      height: image?.height,
    },
  ];

  const textColorToken = themeName === AVAILABLE_THEMES.suprema ? 'var(--color-white)' : undefined;
  const themedTextColor = themeName === AVAILABLE_THEMES.suprema ? undefined : textColor;

  return (
    <BannerWrapper
      data-test={[`${id}-wrapper`]}
      $size={bannerSize}
      $verticalAlignment={verticalAlignment}
      $verticalAlignmentMobile={verticalAlignmentMobile}
      style={{ color: textColorToken }}
      $color={themedTextColor}
    >
      <Grid>
        <GridRow columns={16}>
          <GridItem colStart={ColStart} colSpan={ColSpan}>
            <BannerContentWrapper data-test={[`${id}-content-wrapper`]} $alignment={alignment}>
              <BannerContent data-test={[`${id}-content`]} $alignment={alignment}>
                {superText && (
                  <FadeInSection delay="300ms">
                    <SuperTextElement themeName={themeName} id={id} text={superText} />
                  </FadeInSection>
                )}
                {title && (
                  <FadeInSection delay="600ms">
                    <TitleElement
                      themeName={themeName}
                      tag={titleTag || 'div'}
                      text={title}
                      id={id}
                    />
                  </FadeInSection>
                )}
                {description && (
                  <FadeInSection delay="900ms" $componentSize={bannerSize}>
                    <DescElement themeName={themeName} id={id} text={description} />
                  </FadeInSection>
                )}
                {LinkUrl || link?.url ? (
                  <StyledButtonContainer data-test={[`${id}-CTA-button`]} $alignment={alignment}>
                    <NextLink href={`${LinkUrl || link?.url}`} onClick={handleCTAClick}>
                      <span className="sr-only">{link?.displayName}</span>
                      <CTAContainer>
                        <LiquidButtonElement
                          variant={themeName === AVAILABLE_THEMES.suprema ? 'secondary' : 'none'}
                          text={link?.displayName || ''}
                          width={250}
                          color={buttonColor === 'Light' ? COLORS.secondary : COLORS.primary}
                          textColor={buttonColor === 'Light' ? '#000' : COLORS.white}
                        />
                      </CTAContainer>
                    </NextLink>
                  </StyledButtonContainer>
                ) : (
                  ''
                )}
                <BannerActions data-test={[`${id}-actions`]} $alignment={alignment}>
                  {video && (
                    <PlayVideoWrapper
                      data-test={[`${id}-play-video-wrapper`]}
                      $textColor={playColorScheme}
                    >
                      <PlayButtonWrapper data-test={[`${id}-play-button-wrapper`]}>
                        <ModalBox
                          videoID={video}
                          colorScheme={playColorScheme}
                          data-test={[`${id}-modal`]}
                          title={title}
                          description={description || ''}
                          thumbnailUrls={thumbnailUrls ?? []}
                          embedUrl={embedUrl || ''}
                          uploadDate={uploadDate || ''}
                          duration={duration || ''}
                          interactionCount={interactionCount || 0}
                        />
                      </PlayButtonWrapper>{' '}
                      <Paragraph data-test={[`${id}-video-text`]} tag="div" type="sm">
                        {watchVideoLabel}
                      </Paragraph>
                    </PlayVideoWrapper>
                  )}
                </BannerActions>
              </BannerContent>
            </BannerContentWrapper>
          </GridItem>
        </GridRow>
      </Grid>
      <div className="w-full" data-test={[`${id}-image-wrapper`]}>
        <BannerImage data-test={[`${id}-image-${bannerSize}`]} $size={bannerSize}>
          {isRecipeBanner ? (
            <NextImage
              sources={sourcesRecipes}
              preferredWidth={1920}
              alt="Recipes hero"
              quality={75}
              priority={priority}
            />
          ) : (
            <NextImage
              sources={sources}
              preferredWidth={1920}
              alt="Hero image"
              quality={75}
              priority={priority}
            />
          )}
        </BannerImage>
      </div>
    </BannerWrapper>
  );
};
