import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import {
  BannerContentAlignment,
  BannerContentVerticalAlignment,
  BannerSize,
  ColorScheme,
} from './types';
import { Text as Paragraph } from '../Typography';

const justifyContentMapping = (position: string): string => {
  const mappings: { [key: string]: string } = {
    Top: 'start',
    Middle: 'center',
    Bottom: 'end',
  };

  return mappings[position];
};

interface IBannerWrapperProps {
  $size?: BannerSize;
  $color?: string;
}

interface IBannerContentProps {
  $alignment?: BannerContentAlignment;
  $alignmentMobile?: BannerContentAlignment;
  $verticalAlignment?: BannerContentVerticalAlignment;
  $verticalAlignmentMobile?: BannerContentVerticalAlignment;
}

interface IPlayVideoWrapperProps {
  $textColor?: ColorScheme;
}

export const BannerWrapper = tw.div<{
  $size?: BannerSize;
  $verticalAlignment?: BannerContentVerticalAlignment;
  $verticalAlignmentMobile?: BannerContentVerticalAlignment;
  $color?: string;
}>`
  bg-center
  bg-cover
  w-full
  overflow-hidden
  items-center
  flex
  flex-col
  relative

  ${(props: IBannerContentProps) =>
    props.$verticalAlignmentMobile
      ? `justify-${justifyContentMapping(props?.$verticalAlignmentMobile)}`
      : 'justify-center'}

  ${(props: IBannerContentProps) =>
    props.$verticalAlignmentMobile === 'Bottom' ? 'pb-32 md:pb-0' : ''}

  ${(props: IBannerContentProps) =>
    props.$verticalAlignmentMobile === 'Top' ? 'pt-10 md:pt-0' : ''}

  ${(props: IBannerContentProps) => (props.$verticalAlignment === 'Top' ? 'pt-0 md:pt-10' : '')}
  ${(props: IBannerContentProps) =>
    props.$verticalAlignment
      ? `md:justify-${justifyContentMapping(props?.$verticalAlignment)}`
      : 'md:justify-center'}

  ${(props: IBannerContentProps) =>
    props.$verticalAlignment === 'Bottom' ? 'pb-0 md:pb-32 lg:pb-16' : ''}
  ${(props: IBannerWrapperProps) =>
    props.$size === 'Tall'
      ? 'h-heroCarouselInnerTallMob md:h-heroCarouselInnerTallBannerHeight'
      : 'h-bannerShort'}

  ${(props: IBannerWrapperProps) => (props.$color === 'White' ? 'text-white' : 'text-black')}
    `;
BannerWrapper.displayName = 'BannerWrapper';

export const BannerContentWrapper = tw.div<{
  $alignment?: BannerContentAlignment;
}>`
  ${(props: IBannerContentProps) =>
    props.$alignment ? `text-${props?.$alignment.toLowerCase}` : 'md:text-center'}
`;
BannerContentWrapper.displayName = 'BannerContentWrapper';

export const BannerContent = tw.div<{
  $alignment?: BannerContentAlignment;
}>`
text-center
md:text-left
${(props: IBannerActionsProps) => (props.$alignment === 'Center' ? 'md:text-center' : '')}
`;
BannerContent.displayName = 'BannerContent';

export const PlayVideoWrapper = tw.div<{ $textColor?: ColorScheme }>`
  ${(props: IPlayVideoWrapperProps) => (props.$textColor === 'Black' ? 'text-black' : 'text-white')}
  items-center
  flex
  tracking-wider
`;
PlayVideoWrapper.displayName = 'PlayVideoWrapper';

export const PlayButtonWrapper = tw.div`
  inline-flex
  h-12
  w-12
  mr-2
 `;
PlayButtonWrapper.displayName = 'PlayButtonWrapper';

interface IBannerActionsProps {
  $alignment?: BannerContentAlignment;
}

export const BannerActions = tw.div<{
  $alignment?: BannerContentAlignment;
}>`
flex
items-center
justify-center


${(props: IBannerActionsProps) =>
  props.$alignment === 'Center'
    ? 'text-center'
    : `
  text-center
  md:text-left`}
`;
BannerActions.displayName = 'BannerActions';

export const ButtonContainer = tw.div<{ alignment?: BannerContentAlignment }>`
 relative
 mt-7.5
`;
ButtonContainer.displayName = 'ButtonContainer';

export const StyledButtonContainer = styled(ButtonContainer as any)<{
  $alignment?: BannerContentAlignment;
}>`
  height: 50px;
  width: 250px;
  ${(props: IBannerActionsProps) =>
    props.$alignment === 'Center'
      ? `margin-left:auto;
     margin-right:auto;`
      : ''}

  @media (max-width: 767px) {
    margin-left: auto;
    margin-right: auto;
  }
`;
StyledButtonContainer.displayName = 'StyledButtonContainer';

export const SuperText = tw(Paragraph)<{
  $color?: 'Black' | 'White';
}>`
  mb-2.5
  opacity-70
  pt-0.5

  ${(props: IBannerWrapperProps) => (props.$color === 'White' ? 'text-white' : 'text-darkgray')}
`;
SuperText.displayName = 'SuperText';

export const CTAContainer = styled.div`
  position: absolute;
  transform: translate(-50px, -50px);
`;
CTAContainer.displayName = 'CTAContainer';

export const BannerImage = styled.div<{ $size?: BannerSize }>`
  height: ${(props: IBannerWrapperProps) => (props.$size === 'Tall' ? '580px' : '340px')};
  z-index: -1;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 767px) {
    ${(props: IBannerWrapperProps) => props.$size === 'Short' && 'top: 0;'}
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
`;
