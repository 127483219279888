import { Headline } from '@/components/Headline';
import { Title } from '@/components/Typography';
import { AVAILABLE_THEMES } from '@/styles/constants';
import Image from 'next/image';
import Supreme from '../Supreme.png';

export const TitleElement = ({
  text,
  id,
  themeName,
  tag,
}: {
  text: string;
  id: string;
  themeName?: string;
  tag?: string;
}) => {
  if (themeName === AVAILABLE_THEMES.suprema) {
    return (
      <>
        <Image src={Supreme} alt="Hero image" priority className="mx-auto mb-3" />
        <Headline
          data-test={[`${id}-title`]}
          as="h1"
          type="xl"
          className="font-primary mb-3  hidden"
        >
          {text}
        </Headline>
      </>
    );
  }

  return (
    <Title data-test={[`${id}-title`]} tag={tag || 'div'} type="md">
      {text}
    </Title>
  );
};
